// element.js 全文内容如下，按自己需要引入就好了
import Vue from 'vue'
import {
    Button,
    Form,
    FormItem,
    Input,
    // Message,
    MessageBox,
    Dialog,
    Drawer,
    Icon
} from 'element-ui'
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Drawer)
Vue.use(Icon)
// Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm