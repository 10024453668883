//引入刚才的http.js文件
import https from '../utils/http';

//设置个对象，就不用一个个暴露了，直接暴露对象
let apiFun = {};

/* 获取案例列表 */
apiFun.getlist = function (params) {
    return https.get('/system/case/list', params)
}

/* 新增试用申请 */
apiFun.addDemoUser = function (params) {
    return https.post('/system/demoUser/add', params)
}

//暴露出这个对象
export default apiFun;

