<template>
    <div>
        <div class="mb-foot" v-if="isMobile">
            <div class="company-info">
                <div class="foot-main">
                    <div class="left">
                        <div class="image">
                            <img src="../assets/images/qr_code.png" alt="">
                            <div>关注我们</div>
                        </div>
                    </div>
                    <div class="right">
                        <img src="../assets/images/logo_b.png" alt="" class="logo" style="width: 120px;">
                        <section class="tel-sec">
                            <img src="../assets/images/img_tel.png" alt="" class="icon-image">
                            13661509696
                        </section>
                        <section>
                            <img src="../assets/images/img_email.png" alt="" class="icon-image">
                            sunjian@yishi.tech
                        </section>
                        <section>
                            <img src="../assets/images/img_local.png" alt="" class="icon-image">
                            江苏省苏州市太仓市沙溪镇昭溪路128号14幢01室1049
                        </section>
                    </div>
                </div>
            </div>
            <div class="beian-info">@2023 LabMaster All Right Reserved <a href="https://beian.miit.gov.cn"
                    target="_blank">苏ICP备2023030110号-1</a>
            </div>
        </div>
        <div class="foot" v-else>
            <div class="company-info">
                <div class="foot-left">
                    <img src="../assets/images/logo_b.png" alt="" class="logo">
                    <section class="tel-sec">
                        <img src="../assets/images/img_tel.png" alt="" class="icon-image">
                        13661509696
                    </section>
                    <section>
                        <img src="../assets/images/img_email.png" alt="" class="icon-image">
                        sunjian@yishi.tech
                    </section>
                    <section>
                        <img src="../assets/images/img_local.png" alt="" class="icon-image">
                        江苏省苏州市太仓市沙溪镇昭溪路128号14幢01室1049
                    </section>
                    <section class="code-sec">
                        <div class="image">
                            <img src="../assets/images/qr_code.png" alt="翌实科技小程序">
                            关注我们
                        </div>
                        <div class="image">
                            <img src="../assets/images/qr_code.png" alt="翌实科技小程序">
                            小程序体验
                        </div>
                    </section>
                </div>
                <div class="foot-right">
                    <section>产品
                        <p>
                            <a :href="`${nowURL}/pc_envPage`" target="_blank">EMS环境设备监控系统</a>
                        </p>
                        <p>
                            <a :href="`${nowURL}/pc_yangbenPage`" target="_blank">试剂样本管理系统</a>
                        </p>
                        <p>
                            <a :href="`${nowURL}/pc_storagePage`" target="_blank">医药企业耗材管理系统</a>
                        </p>
                    </section>
                    <section>解决方案
                        <p>
                            <a :href="`${nowURL}/pc_env_solutionPage`" target="_blank">实验室环境监控解决方案</a>
                        </p>
                        <p>
                            <a :href="`${nowURL}/pc_make_solutionPage`" target="_blank">医药制造与研发解决方案</a>
                        </p>
                        <p>
                            <a :href="`${nowURL}/pc_yangben_solutionPage`" target="_blank">样本管理解决方案</a>
                        </p>
                    </section>
                    <section>
                        <a :href="`${nowURL}/pc_casePage`" target="_blank">客户案例</a>
                    </section>
                    <section>
                        <a :href="`${nowURL}/pc_aboutUsPage`" target="_blank">关于我们</a>
                    </section>
                </div>
            </div>
            <div class="beian-info">
                <div class="lianjie-list">
                    <div v-for="(item, index) in lianjieList" :key="index">
                        <a :href="item.url" target="_blank">{{ item.name }}</a>
                        <span style="margin: 0 10px;" v-if="index < lianjieList.length - 1">|</span>
                    </div>
                </div>
                @2023 LabMaster All Right Reserved 
                <a href="https://beian.miit.gov.cn" target="_blank">苏ICP备2023030110号-1</a>
                <div class="lianjie">友情链接</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isMobile: false,
            lianjieList: [{
                name: '太仓星药港',
                url: 'https://www.ace-park.com/newsdetail?id=348.html'
            }, {
                name: '探实生物',
                url: 'http://www.shtsbio.com/'
            }, {
                name: '生研湾',
                url: 'https://www.sywlabbay.com/'
            }, {
                name: '赛岚医药',
                url: 'https://www.cytosinlab.com/'
            }, {
                name: '甲贝医药',
                url: 'https://www.shellbiotech.com/'
            }, {
                name: '瑞宙生物',
                url: 'http://www.reinovaxbio.com/'
            }, {
                name: '申淇医疗',
                url: 'https://www.sqmedical.com/'
            }, {
                name: '酷班生物',
                url: 'https://www.coolbond.com.cn/'
            }, {
                name: '泰坦科技',
                url: 'https://www.titansci.com/'
            }, {
                name: '探索平台',
                url: 'https://www.tansoole.com/'
            }],
            nowURL: null,
        }
    },
    created() {
        if (this._isMobile()) {
            this.isMobile = true
        } else {
            this.isMobile = false
        }
        const currentURL = window.location.href;
        const lastIndex = currentURL.lastIndexOf('/');
        this.nowURL = currentURL.substring(0, lastIndex);
    },
    methods: {
        // 判断是否是手机端，如果是，返回true
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag
        },
    }
}
</script>
<style lang="less" scoped>
.mb-foot {
    height: 228px;
    box-sizing: border-box;
    background: #1b1e1e;
    color: #FFF;

    .company-info {
        height: 180px;
        border-bottom: 1px solid #313434;
        display: flex;
    }

    .beian-info {
        text-align: center;
        line-height: 30px;
        color: #585b5b;
        width: 100%;
        margin-top: 6px;
        font-size: 12px;
        position: relative;
        a{
            color: #585b5b;
        }

        a:hover {
            color: #585b5b;
        }

        a:visited {
            color: #585b5b;
        }
        .lianjie{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: none;
        }
        .lianjie-list{
            display: none;
        }
    }

    .foot-main {
        display: flex;
        padding: 30px 15px;

        .left {
            .image {
                padding: 5px;
                text-align: center;
                background: #FFF;
                border-radius: 5px;
                width: 110px;
                color: #000;
                text-align: center;

                img {
                    width: 88px;
                    height: 88px;
                    margin-bottom: 5px;
                }
            }
        }

        .right {
            margin-left: 20px;

            section {
                margin-top: 10px;
                font-size: 12px;

                .icon-image {
                    margin-right: 2px;
                    width: 15px;
                }
            }
        }
    }

}

.foot {
    min-width: 1280px;
    width: 100%;
    height: 540px;
    box-sizing: border-box;
    background: #1b1e1e;
    color: #FFF;

    .company-info {
        height: 420px;
        border-bottom: 1px solid #313434;
        display: flex;
    }

    .beian-info {
        text-align: center;
        line-height: 60px;
        color: #585b5b;
        width: 100%;
        position: relative;
        padding-top: 30px;

        a{
            color: #585b5b;   
        }

        a:hover {
            color: #585b5b;
        }

        a:visited {
            color: #585b5b;
        }

        .lianjie{
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            line-height: 1;
            background: #1b1e1e;
            width: 100px;
            font-size: 17px;
            color: #FFF;
        }
        .lianjie-list{
            display: flex;
            color: #FFF;
            line-height: 1;
            justify-content: center;
            a{
                color: #FFF;
            }
        }
    }

    .foot-main {
        display: flex;
        padding: 30px 15px;

        .left {
            .image {
                padding: 5px;
                text-align: center;
                background: #FFF;
                border-radius: 5px;
                width: 118px;
                color: #000;

                img {
                    width: 88px;
                    height: 88px;
                    margin-bottom: 5px;
                }
            }
        }

        .right {
            margin-left: 20px;

            section {
                margin-top: 10px;
                font-size: 12px;

                .icon-image {
                    margin-right: 2px;
                    width: 15px;
                }
            }
        }
    }

    .foot-left {
        margin-left: 20%;
        padding-top: 40px;

        .logo {
            width: 150px;
        }

        section {
            display: flex;
            align-items: center;
            margin: 20px 0;

            .icon-image {
                margin-right: 10px;
            }
        }

        .tel-sec {
            font-size: 25px;
        }

        .image {
            padding: 5px;
            text-align: center;
            background: #FFF;
            border-radius: 5px;
            width: 118px;
            color: #000;

            img {
                width: 108px;
                height: 108px;
                margin-bottom: 5px;
            }
        }

        .image:first-of-type {
            margin-right: 60px;
        }

        .code-sec {
            margin-top: 30px;
        }
    }

    .foot-right {
        display: flex;
        justify-content: space-between;
        width: 40%;
        margin-left: 5%;
        padding-top: 75px;
        line-height: 42px;

        section {

            a {
                cursor: pointer;
                color: #FFF;
            }

            p:first-of-type {
                margin-top: 10px;
            }
        }
    }
}
</style>