<template>
    <header class="navbar" :class="{ 'mouseenter-navbar': isMouseenter && !_isMobile(), 'mb-logo': _isMobile() }"
        @mouseenter="isMouseenter = true" @mouseleave="isMouseenter = false">
        <div class="logo" v-if="_isMobile()">
            <img src="../assets/images/logo.png" alt="翌实科技官网">
        </div>
        <div class="logo" v-else>
            <a :href="`${nowURL}/pc_indexPAge`" target="_blank" @click="handClick('/pc_indexPage', 0)" v-if="isMouseenter">
                <img src="../assets/images/logo.png" alt="翌实科技官网">
            </a>
            <a :href="`${nowURL}/pc_indexPage`" target="_blank" @click="handClick('/pc_indexPage', 0)" v-else>
                <img src="../assets/images/logo_b.png" alt="翌实科技官网">
            </a>
        </div>
        <div class="menu" v-if="_isMobile()" @click="mb_drawer = !mb_drawer">≡</div>
        <nav v-if="!_isMobile()">
            <ul>
                <li v-for="(item, index) in title_list" :key="index" :class="{ 'active': activeStep === index }">
                    <a :href="`${nowURL}${item.url}`" @click="jump(index)" v-if="item.url" target="_blank">{{ item.title
                    }}</a>
                    <span v-else @click="jump(index)">{{ item.title }}</span>
                    <div class="navbar-container" v-show="false" style="position: fixed;" v-if="item.list.length > 0">
                        <div v-for="(i2, i3) in item.list" :key="i3 + '1'" class="container-children">
                            <a :href="`${nowURL}${i2.url}`" @click.stop="handClick(i2.url, index)" target="_blank"
                                class="children-icon"><img :src="i2.icon" alt=""></a>
                            <div class="text">
                                <div>
                                    <a :href="`${nowURL}${i2.url}`" @click.stop="handClick(i2.url, index)"
                                        target="_blank">{{ i2.name }}</a>
                                </div>
                                <div class="description">{{ i2.description }}</div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
        <div class="nav-btn" v-if="!_isMobile()">
            <div class="tryOut-btn" @click="apply">申请试用</div>
            <div class="login-btn" @click="login">平台登陆</div>
        </div>
        <el-dialog :close-on-click-modal="false" :visible.sync="show" width="850px" :before-close="handleClose"
            custom-class="dialog-box" :modal-append-to-body="false">
            <div class="box">
                <img src="../assets/images/tryout-bg.png" alt="申请试用" style="height: 487px;">
                <div class="free-trial-right">
                    <p class="title">
                        申请试用
                    </p>
                    <div style="margin-top: 25px;">
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                            <el-form-item label="姓名" prop="name" label-width="80px">
                                <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号" prop="phonenumber" label-width="80px">
                                <el-input v-model="ruleForm.phonenumber" placeholder="请输入手机号"></el-input>
                            </el-form-item>
                            <el-form-item label="行业" prop="business" label-width="80px">
                                <el-input v-model="ruleForm.business" placeholder="请输入行业"></el-input>
                            </el-form-item>
                            <el-form-item label="机构" prop="organization" label-width="80px">
                                <el-input v-model="ruleForm.organization" placeholder="请输入机构"></el-input>
                            </el-form-item>
                            <el-form-item label="邮箱" prop="email" label-width="80px">
                                <el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
                            </el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                            <div class="explain-text">提交信息后，我们将在审核通过后的两个工作日内给您回电</div>
                        </el-form>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-drawer :visible.sync="mb_drawer" direction="left" :modal-append-to-body="false" :show-close="false" size="100%">
            <div class="drawer-box">
                <div class="drawer-item" v-for="(item, index) in title_list" :key="index">
                    <div class="drawer-item-parent" :class="{ 'drawer-active': activeStep === index }">
                        <a :href="`${nowURL}${item.url}`" @click="jump(index)" v-if="item.url" target="_blank">{{ item.title
                        }}</a>
                        <span v-else @click="jump(index)">{{ item.title }}</span>
                        <span class="el-icon-arrow-down" v-if="item.list.length > 0"></span>
                    </div>
                    <div class="drawer-item-child" v-show="item.mb_flag">
                        <div class="drawer-item-child-item" v-for="(i2, index2) in item.list" :key="index2 + 'child'" v-show="index2 < 3">
                            <a :href="`${nowURL}${i2.url}`" @click.stop="handClick(i2.url, index)" target="_blank"
                                class="drawer-item-child-item-icon"><img :src="i2.icon" alt=""></a>
                            <div class="drawer-item-child-item-text">
                                <div>
                                    <a :href="`${nowURL}${i2.url}`" @click.stop="handClick(i2.url, index)"
                                        target="_blank">{{ i2.name }}</a>
                                </div>
                                <div class="drawer-item-child-item-description">{{ i2.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
    </header>
</template>
  
<script>
export default {
    props: {
        isBgColor: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title_list: [{
                title: "首页",
                url: this._isMobile() ? '/mb_indexPage' : '/pc_indexPage',
                list: [],
                mb_flag: false,
            }, {
                title: "产品介绍",
                list: [{
                    icon: require('@/assets/images/img_nav_p1.png'),
                    description: '定制的监控解决方案，确保医药、医疗和实验室设施的环境安全和合规性。',
                    name: 'EMS环境设备监控系统',
                    url: this._isMobile() ? '/mb_envPage' : '/pc_envPage'
                }, {
                    icon: require('@/assets/images/img_nav_p2.png'),
                    description: '精确追踪和管理样本和试剂，提高医疗和生命科学领域的实验效率和准确性。',
                    name: '试剂样本管理系统',
                    url: this._isMobile() ? '/mb_yangbenPage' : '/pc_yangbenPage'
                }, {
                    icon: require('@/assets/images/img_nav_p3.png'),
                    description: '优化医药耗材供应链，确保医疗设备生产和医疗服务的无缝运作。',
                    name: '医药企业耗材管理系统',
                    url: this._isMobile() ? '/mb_storagePage' : '/pc_storagePage'
                }, {
                    icon: require('@/assets/images/4_img_nav4.png'),
                    description: '卵巢癌早筛与检测的便携式、数字化解决方案',
                    name: '体外诊断（IVD）试剂',
                    url: this._isMobile() ? '/mb_ivdPage' : '/pc_ivdPage'
                }],
                mb_flag: false,
            }, {
                title: "解决方案",
                list: [{
                    icon: require('@/assets/images/4_img_nav1.png'),
                    description: '为实验室客户提供卓越的环境管理',
                    name: '实验室环境监控解决方案',
                    url: this._isMobile() ? '/mb_env_solutionPage' : '/pc_env_solutionPage'
                }, {
                    icon: require('@/assets/images/4_img_nav2.png'),
                    description: '提高医药生产效率，支持创新研发',
                    name: '医药制造与研发解决方案',
                    url: this._isMobile() ? '/mb_make_solutionPage' : '/pc_make_solutionPage'
                }, {
                    icon: require('@/assets/images/4_img_nav3.png'),
                    description: '实现精确全生命周期管理，提升样本管理效率',
                    name: '样本管理解决方案',
                    url: this._isMobile() ? '/mb_yangben_solutionPage' : '/pc_yangben_solutionPage'
                }],
                mb_flag: false,
            }, {
                title: "客户案例",
                list: [],
                url: this._isMobile() ? '/mb_casePage' : '/pc_casePage',
                mb_flag: false,
            }, {
                title: "关于我们",
                list: [],
                url: this._isMobile() ? '/mb_aboutUsPage' : '/pc_aboutUsPage',
                mb_flag: false,
            }],
            isMouseenter: false,
            activeStep: 0,
            show: false,
            ruleForm: {
                business: "",
                email: "",
                name: "",
                organization: "",
                phonenumber: "",
                source: ""
            },
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                business: [
                    { required: true, message: '请输入行业', trigger: 'blur' }
                ],
                organization: [
                    { required: true, message: '请输入机构', trigger: 'blur' }
                ],
                phonenumber: [
                    { required: true, message: '请输入正确的手机号码', trigger: 'blur', pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/ }
                ],
                email: [
                    { required: true, message: '请输入正确的邮箱', trigger: ["blur", "change"], type: "email" }
                ]
            },
            // isBgColor2: false
            mb_drawer: false,
            nowURL: null,
        };
    },
    created() {
        window.addEventListener('setItem', () => {
            this.activeStep = Number(sessionStorage.getItem('nav-activeStep'))
        })
        if (sessionStorage.getItem('nav-activeStep')) {
            this.activeStep = Number(sessionStorage.getItem('nav-activeStep'))
        }
        this.$bus.$on('apply', this.apply)
        const currentURL = window.location.href;
        const lastIndex = currentURL.lastIndexOf('/');
        const route = currentURL.substring(lastIndex)
        this.nowURL = currentURL.substring(0, lastIndex);
        this.title_list.forEach((item, index) => {
            if (item.url) {
                if (item.url === route) {
                    this.$resetSetItem('nav-activeStep', index)
                }
            } else {
                const arr = item.list.filter(it2 => it2.url === route)
                if (arr.length > 0) {
                    this.$resetSetItem('nav-activeStep', index)
                }
            }
        })
    },
    beforeDestroy() {
        window.removeEventListener('setItem')
    },
    watch: {
        isBgColor(newData) {
            console.log(newData);
            // this.isBgColor2 = newData
        }
    },
    methods: {
        jump(index) {
            if (this.title_list[index].list.length === 0) {
                if (this._isMobile()) {
                    this.mb_drawer = false
                }
            } else {
                if (this._isMobile()) {
                    this.title_list = this.title_list.map(item => {
                        item.mb_flag = false
                        return item
                    })
                    this.title_list[index].mb_flag = !this.title_list[index].mb_flag
                }
            }
        },
        handClick(url, index) {
            if (url) {
                // window.open(`${this.nowURL}${url}`)
            }
            if (this._isMobile()) {
                this.mb_drawer = false
            }
        },
        // 判断是否是手机端，如果是，返回true
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag instanceof Array
        },
        // 申请试用
        apply() {
            this.show = true
        },
        // 平台登陆
        login() {
            window.open('https://saas.labmaster.top/')
        },
        submitForm(ruleForm) {
            // debugger
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.$apiFun.addDemoUser(this.ruleForm).then(res => {
                        // this.$message.success('申请成功');
                        this.$refs[ruleForm].resetFields();
                        this.show = false
                    })
                } else {
                    return false;
                }
            });
        },
    },
}
</script>
<style lang="less" scoped>
.navbar {
    width: 100%;
    height: 70px;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 100;
    color: #FFF;
    justify-content: space-between;
    background-color: rgba(39, 39, 39, 0.8);
    box-sizing: border-box;
    font-size: 16px;
    padding-right: 26px;

    a {
        color: #FFF;
    }

    .logo {
        text-align: center;
        flex-grow: 1;

        img {
            cursor: pointer;
        }
    }

    .menu {
        flex-grow: 1;
        text-align: right;
        font-size: 34px;
    }

    nav {
        flex-grow: 1;
    }

    ul {
        display: flex;
        justify-content: space-between;

        li {
            line-height: 70px;
            min-width: 100px;
            text-align: center;
            position: relative;
        }

        .active::after {
            content: '';
            display: block;
            width: 100%;
            height: 4px;
            background: #42B0FF;
            border-radius: 50px;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    .nav-btn {
        display: flex;
        width: 200px;
        justify-content: space-between;
        margin: 0 20px;

        .tryOut-btn,
        .login-btn {
            width: 90px;
            height: 30px;
            border-radius: 50px;
            line-height: 30px;
            text-align: center;
            cursor: pointer;
        }

        .tryOut-btn {
            border: 1px solid #FFF;
        }

        .login-btn {
            background-color: #42B0FF;
        }
    }
}

.mb-logo {
    background: rgba(255, 255, 255, 0.8);
    color: #666;

    .logo {
        text-align: left;

        img {
            width: 60%;
        }
    }
}

.mouseenter-navbar {
    background-color: rgba(212, 212, 212, 0.8);
    color: #343434;

    .tryOut-btn {
        border: 1px solid #42B0FF !important;
        color: #42B0FF;
    }

    a {
        color: #343434;
    }

    li:hover {
        background-color: #000;

        a,
        span {
            color: #FFF;
        }

        .navbar-container {
            display: flex !important;
            width: 100%;
            height: 240px;
            background-color: #FFF;
            top: 70px;
            left: 0;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            color: #343434;
            flex-wrap: wrap;
            transition: top 2s, left 2s;
            padding-left: 17%;

            .container-children {
                width: 50%;
                text-align: center;
                display: flex;
                align-items: center;

                .children-icon {
                    margin-left: 5%;
                }

                .text {
                    line-height: 25px;
                    margin-left: 20px;
                    display: inline-block;
                    text-align: left;

                    a {
                        color: #343434;
                    }

                    .description {
                        color: #8c8c8c;
                        font-size: 12px;
                    }
                }
            }

            // .container-children:nth-child(1),
            // .container-children:nth-child(3) {
            //     margin-left: 39%;
            // }

        }
    }
}

.box {
    display: flex;

    .free-trial-right {
        width: 100%;
        padding: 0 25px;
        text-align: center;

        .title {
            font-size: 30px;
            font-weight: bolder;
            font-style: italic;
            color: #42B0FF;
            position: relative;
            letter-spacing: 3px;
        }

        .title::after {
            content: "";
            display: block;
            width: 25%;
            height: 3px;
            background-color: #42b0ff;
            position: absolute;
            left: 37%;
            bottom: -7px;
        }

        .el-button {
            width: 100%;
        }

        .explain-text {
            color: #999;
            font-size: 12px;
        }
    }
}

.drawer-box {
    width: 260px;
    padding: 0 10px;

    .drawer-item {

        .drawer-item-parent {
            margin-top: 15px;
            padding-left: 10px;
            line-height: 36px;
        }

        a {
            color: #343434;
        }

        .drawer-active {
            color: #FFF;
            background: #42B0FF;
            border-radius: 5px;
        }

        .drawer-item-child {
            .drawer-item-child-item {
                display: flex;
                padding: 20px 0 0 20px;

                .drawer-item-child-item-icon {
                    width: 38px;
                    height: 38px;

                    img {
                        width: 100%;
                    }
                }

                .drawer-item-child-item-text {
                    margin-left: 10px;
                    font-size: 14px;

                    .drawer-item-child-item-description {
                        font-size: 12px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    .drawer-item:first-of-type {
        .drawer-item-parent {
            margin-top: 0;
        }
    }
}
</style>