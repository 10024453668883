import Vue from 'vue'
import App from './App.vue'
import router from './router'
import apiFun from "@/api/home.js";
import MetaInfo from 'vue-meta-info'
import scroll from 'vue-seamless-scroll';
import './utils/element'
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$apiFun = apiFun;//请求接口api
Vue.prototype.$monitorVisualArea = function (el) {
  if (el) {
    let rect = el.getBoundingClientRect();
    return (
      rect.top >=
      (window.innerHeight || document.documentElement.clientHeight) ||
      rect.bottom <= 0
    );
  }
};


/*实时获取 sessionStorage 的值*/
Vue.prototype.$resetSetItem = function (key, newVal) {
  //注意categoryNum为要监听的属性，实际开发中请自行修改
  if (key === 'nav-activeStep') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}



Vue.use(scroll);
Vue.use(MetaInfo)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
    // 往Vue的原型上放一个 全局事件总线 $bus（可以任意命名，但是约定俗成用$bus），
    // 在子组件VueComponent中就能调用到这个原型里的东西，因为子组件原型会往Vue的原型上面找
  },
  //这里与vue.config.js中的事件名相对应
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')