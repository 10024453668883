import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

const routes = [
  {
    path: "/",
    redirect: flag instanceof Array ? '/mb_indexPage' : "/pc_indexPage",
  },
  {
    path: '/pc_indexPage',
    component: () => import('@/views/PC/index.vue'),
  },
  {
    path: '/pc_envPage',
    component: () => import('@/views/PC/env.vue'),
  },
  {
    path: '/pc_yangbenPage',
    component: () => import('@/views/PC/yangben.vue'),
  },
  {
    path: '/pc_storagePage',
    component: () => import('@/views/PC/storage.vue'),
  },
  {
    path: '/pc_casePage',
    component: () => import('@/views/PC/case.vue'),
  },
  {
    path: '/pc_env_solutionPage',
    component: () => import('@/views/PC/env_solution.vue'),
  },
  {
    path: '/pc_make_solutionPage',
    component: () => import('@/views/PC/make_solution.vue'),
  },
  {
    path: '/pc_yangben_solutionPage',
    component: () => import('@/views/PC/yangben_solution.vue')
  },
  {
    path: '/pc_aboutUsPage',
    component: () => import('@/views/PC/aboutUs.vue')
  },
  {
    path: '/pc_ivdPage',
    component: () => import('@/views/PC/ivd.vue')
  },
  {
    path: '/mb_indexPage',
    component: () => import('@/views/Mobile/index.vue')
  },
  {
    path: '/mb_yangbenPage',
    component: () => import('@/views/Mobile/yangben.vue'),
  },
  {
    path: '/mb_envPage',
    component: () => import('@/views/Mobile/env.vue'),
  },
  {
    path: '/mb_storagePage',
    component: () => import('@/views/Mobile/storage.vue'),
  },
  {
    path: '/mb_casePage',
    component: () => import('@/views/Mobile/case.vue'),
  },
  {
    path: '/mb_aboutUsPage',
    component: () => import('@/views/Mobile/aboutUs.vue'),
  },
  {
    path: '/mb_env_solutionPage',
    component: () => import('@/views/Mobile/env_solution.vue'),
  },
  {
    path: '/mb_make_solutionPage',
    component: () => import('@/views/Mobile/make_solution.vue'),
  },
  {
    path: '/mb_yangben_solutionPage',
    component: () => import('@/views/Mobile/yangben_solution.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // 跳转之后自动定位到顶部
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router
