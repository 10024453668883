<template>
  <div id="app" :style="{ paddingTop: _isMobile() ? '70px' : 'none', minWidth: _isMobile() ? '400px' : 'none' }">
    <navbar :isBgColor="isBgColor" />
    <router-view />
    <foot />
  </div>
</template>
<script>
import navbar from "./components/navbar.vue"
import foot from "./components/foot.vue"
export default {
  components: {
    navbar, foot
  },
  data() {
    return {
      isBgColor: true
    }
  },
  watch: {
    // 方法1
    '$route'(to, from) { //监听路由是否变化
      if (to.path === '/pc_envPAge') {
        this.isBgColor = false
      } else {
        this.isBgColor = true
      }
    },
    beforeDestroy() {     // 绑在$bus上的 都要主动销毁，因为App.vue销毁之后，$bus还在，在上面注册的事件都还在占空间，所以销毁时得一起$off掉
      this.$bus.$off(['apply'])	// 同时关闭多个用数组形式放进去
    }
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag instanceof Array
    },
  }
}
</script>

<style lang="less">
@import "./assets/style/index.less";
@import "./assets/style/reset.css";
</style>
